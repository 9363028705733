/*
 * app kit
 *
 */


$c-main : #6d4836;


/** Reset
****************************************/
@import 'common/_reset';

/** common style
****************************************/
@import 'common/_common';

/*
@import 'easy/style';
*/
@import 'ocha/style';

body {
	background-image: url(/common/pc/img/bg.jpg);
}
