@charset "UTF-8";
/*
 * app kit
 *
 */
/** Reset
****************************************/
/*
 * reset
 */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

html, body {
  min-width: 320px; }

body {
  line-height: 1.4;
  background: #fff;
  color: #444;
  font-family: "�q���M�m�p�S Pro W3", "Hiragino Kaku Gothic Pro", "���C���I", Meiryo, Osaka, "�l�r �o�S�V�b�N", "MS PGothic", sans-serif;
  font-size: 14px;
  letter-spacing: 0.08em; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  color: #444; }

a:hover {
  text-decoration: none; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, select {
  vertical-align: middle; }

img {
  margin: 0;
  border: 0;
  vertical-align: middle; }

em {
  font-style: normal; }

/** common style
****************************************/
/*
 * common
 */
ul.disc {
  margin-left: 1em;
  list-style: disc; }

ul.circle {
  margin-left: 1em;
  list-style: circle; }

ol.alpha {
  margin-left: 1.5em;
  list-style: lower-alpha; }

ol.decimal {
  margin-left: 2.5em;
  list-style: decimal; }

ul.disc, ul, circle, ol.alpha, ol.decimal {
  margin-bottom: 1em; }

ul.disc:last-child, ul, circle:last-child, ol.alpha:last-child, ol.decimal:last-child {
  margin-bottom: 0; }

dl.column {
  margin-top: 30px; }

dl.column dt {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold; }

dl.column dd {
  overflow: hidden;
  margin-bottom: 30px; }

dl.column dd p {
  margin-bottom: 1em; }

dl.column dd p:last-child {
  margin-bottom: 0; }

table.tablePrice {
  border-collapse: inherit;
  border-spacing: 3px; }

table.tablePrice th, table.tablePrice td {
  padding: 15px 8px; }

table.tablePrice th {
  background: #ececec;
  text-align: left; }

.tcAccent {
  color: #f33; }

hr.breaking {
  height: 1px;
  border: none;
  border-top: 1px solid #ccc; }

/*
@import 'easy/style';
*/
/** header
****************************************/
#grobalHeader {
  padding: 8px 0 6px;
  background: #6d4836;
  color: #fff;
  text-align: center; }

/** topic path
****************************************/
#areaTopicPath {
  overflow: hidden;
  border-bottom: 1px solid #ececec; }

#areaTopicPath .topicPath {
  width: 900px;
  margin: 10px auto; }

#areaTopicPath .topicPath, #areaTopicPath .topicPath a {
  color: #999;
  font-size: 12px; }

/** content
****************************************/
#content {
  width: 900px;
  margin: 20px auto 60px; }

#content a {
  color: #51a2f4; }

#content h1 {
  margin: 25px auto;
  color: #6d4836;
  font-size: 24px;
  font-weight: bold; }

/** App DL Button **/
.appDL {
  overflow: hidden;
  padding: 40px 0;
  background-image: url(/common/pc/img/bg_2.jpg);
  background-repeat: repeat;
  text-align: center;
  color: #fff; }

.appDL p {
  font-size: 17px; }

.appDL em {
  color: #ff0; }

.appDL li {
  display: inline-block;
  margin: 20px 12px 0; }

.appDL a:hover img {
  opacity: 0.8; }

.appDL .btnApple {
  opacity: 0.1;
  -ms-filter: "alpha(opacity=10)"; }

/** [AD]Appliv **/
.txtAd {
  line-height: 1.6;
  padding: 1.2em 0;
  background-color: #0057ad;
  color: #cbddee;
  font-size: 12px;
  text-align: center; }

.txtAd a {
  color: #cbddee; }

/** form
****************************************/
.formDL dt {
  margin: 20px 0 0; }

.formDL dt {
  font-size: 16px;
  font-weight: bold; }

.formDL dd {
  margin-bottom: 1em; }

.formDL input[type="text"], .formDL input[type="email"] {
  width: 60%;
  height: 2em;
  line-height: 2em;
  border: 1px solid #ccc; }

.formDL button#btnSubmit {
  display: block;
  width: 100px;
  height: 30px; }

.formDL textarea {
  width: 60%;
  height: 6em;
  border: 1px solid #ccc;
  font-size: 100%; }

.formDL select {
  height: 2em;
  line-height: 2em;
  border: 1px solid #ccc; }

.formDL .formErr {
  border: 1px solid #f33 !important; }

.formDL .err {
  margin-top: 3px;
  color: #f33; }

/** footer
****************************************/
footer {
  overflow: hidden;
  padding: 20px 0;
  border-top: 1px solid #d7d7d7;
  background: #ececec;
  text-align: center; }

footer ul {
  margin-bottom: 20px; }

footer li {
  display: inline-block;
  margin: 0 10px; }

footer p {
  font-size: 12px; }

/** UP Button
****************************************/
#btnUP {
  position: fixed;
  right: 20px;
  bottom: 30px;
  display: none;
  width: 80px;
  height: 80px;
  line-height: 20px;
  border: 3px solid #ccc;
  border-radius: 50px;
  background: #fff;
  text-align: center;
  color: #444;
  cursor: pointer; }

#btnUP:before {
  display: block;
  margin-bottom: 4px;
  content: "▲";
  color: #999;
  font-size: 16px;
  font-weight: bold; }

#btnUP:hover {
  border: 3px solid #6d4836;
  color: #6d4836; }

#btnUP:hover:before {
  color: #6d4836; }

/****************************************
Other Device(SP/Tablet)
****************************************/
@media screen and (max-width: 984px) {
  /** topic path **/
  #areaTopicPath .topicPath {
    width: 90%; }
  /** content **/
  #content {
    width: 90%; }
  /** App DL Button **/
  .appDL {
    padding: 16px 0; }
  .appDL p {
    font-size: 16px; }
  .appDL li {
    width: 28%;
    margin: 8px 12px; }
  .appDL img {
    width: 100%; }
  /** footer
	****************************************/
  footer {
    overflow: hidden;
    padding: 20px 0;
    border-top: 1px solid #d7d7d7;
    background: #ececec;
    text-align: center; }
  footer ul {
    margin-bottom: 20px; }
  footer li {
    display: inline-block;
    margin: 0 10px; }
  footer p {
    font-size: 10px; }
  /** UP Button
	****************************************/
  #btnUP {
    position: fixed;
    right: 20px;
    bottom: 30px;
    display: none;
    width: 68px;
    height: 68px;
    line-height: 12px;
    border: 3px solid #ccc;
    border-radius: 50px;
    background: #fff;
    text-align: center;
    color: #444;
    cursor: pointer; }
  #btnUP:before {
    display: block;
    margin-bottom: 4px;
    content: "▲";
    color: #999;
    font-size: 14px;
    font-weight: bold; }
  #btnUP:hover {
    border: 3px solid #6d4836;
    color: #6d4836; }
  #btnUP:hover:before {
    color: #6d4836; } }

@media screen and (max-width: 700px) {
  body {
    font-size: 12px; }
  dl.column {
    margin-top: 10px; }
  .spimg_full {
    width: 100%; }
  /** topic path **/
  #areaTopicPath .topicPath {
    width: 90%;
    margin: 10px auto; }
  /** content **/
  #content {
    width: 90%;
    margin: 10px auto 30px; }
  #content h1 {
    margin: 10px auto;
    color: #6d4836;
    font-size: 18px;
    font-weight: bold; }
  /** App DL Button **/
  .appDL {
    padding: 16px 0; }
  .appDL p {
    font-size: 12px; }
  .appDL li {
    width: 42%;
    margin: 8px 8px; }
  .appDL img {
    width: 100%; }
  .txtAd {
    padding: 1.2em 0.5em;
    font-size: 11px;
    text-align: left; }
  /** form **/
  .formDL dt {
    margin: 20px 0 0; }
  .formDL dt {
    font-size: 14px; }
  .formDL input[type="text"], .formDL input[type="email"] {
    width: 100%; }
  .formDL button#btnSubmit {
    display: block;
    width: 100px;
    height: 30px;
    margin: 0 auto;
    font-size: 16px; }
  .formDL textarea {
    width: 100%;
    height: 8em; } }

body {
  background-image: url(/common/pc/img/bg.jpg); }

