/*
 * reset
 */

html, body, div, span, object, iframe,  
h1, h2, h3, h4, h5, h6, p, blockquote, pre,  
abbr, address, cite, code,  
del, dfn, em, img, ins, kbd, q, samp,  
small, strong, sub, sup, var, b, i,  
dl, dt, dd, ol, ul, li,  
fieldset, form, label, legend,  
table, caption, tbody, tfoot, thead, tr, th, td,  
article, aside, canvas, details, figcaption, figure,  
footer, header, hgroup, menu, nav, section, summary,  
time, mark, audio, video {
	margin: 0;  
	padding: 0;  
	border: 0;  
	outline: 0;  
	font-size: 100%;  
	vertical-align: baseline;  
	background: transparent;  
}
html, body {
	min-width: 320px;
}
body {
	line-height: 1.4;
	background: #fff;
	color: #444;
	font-family:"�q���M�m�p�S Pro W3", "Hiragino Kaku Gothic Pro", "���C���I", Meiryo, Osaka, "�l�r �o�S�V�b�N", "MS PGothic", sans-serif;
	font-size: 14px; 
	letter-spacing: 0.08em;
}
article,aside,details,figcaption,figure,  
footer,header,hgroup,menu,nav,section {
	display: block;  
}
ul, ol {
	list-style: none;  
}
blockquote, q {
	quotes: none;  
}

a {
	margin: 0;  
	padding: 0;  
	vertical-align: baseline;  
	background: transparent;
	color: #444;
}
a:hover {
	text-decoration: none;
}
ins {
	background-color: #ff9;  
	color: #000;  
	text-decoration: none;  
}
mark {
	background-color: #ff9;  
	color: #000;  
	font-style: italic;  
	font-weight: bold;  
}
del {
	text-decoration: line-through;  
}
abbr[title], dfn[title] {
	border-bottom: 1px dotted;  
	cursor: help;  
}
table {
	border-collapse: collapse;  
	border-spacing: 0;  
}
input, select {
	vertical-align: middle;  
}
img {
	margin: 0;
	border: 0;
	vertical-align: middle;
}
em { font-style: normal; }