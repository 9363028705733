/*
 * common
 */
ul.disc {
	margin-left: 1em;
	list-style: disc;
}
ul.circle {
	margin-left: 1em;
	list-style: circle;
}
ol.alpha {
	margin-left: 1.5em;
	list-style: lower-alpha;
}
ol.decimal {
	margin-left: 2.5em;
	list-style: decimal;
}
ul.disc, ul,circle,
ol.alpha, ol.decimal { margin-bottom: 1em; }

ul.disc:last-child, ul,circle:last-child,
ol.alpha:last-child, ol.decimal:last-child { margin-bottom: 0; }

dl.column { margin-top: 30px; }
dl.column dt {
	margin-bottom: 4px;
	font-size: 16px;
	font-weight: bold;
}
dl.column dd {
	overflow: hidden;
	margin-bottom: 30px;
}
dl.column dd p { margin-bottom: 1em; }
dl.column dd p:last-child { margin-bottom: 0; }

table.tablePrice {
	border-collapse: inherit;
	border-spacing: 3px;
}
table.tablePrice th,
table.tablePrice td {
	padding: 15px 8px;
}
table.tablePrice th {
	background: #ececec;
	text-align: left;
}

.tcAccent { color: #f33; }

hr.breaking {
	height: 1px;
	border: none;
	border-top: 1px solid #ccc;
}