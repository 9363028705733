
/** header
****************************************/
#grobalHeader {
	padding: 8px 0 6px;
	background: $c-main;
	color: #fff;
	text-align: center;
}

/** topic path
****************************************/
#areaTopicPath {
	overflow: hidden;
	border-bottom: 1px solid #ececec;
}
#areaTopicPath .topicPath {
	width: 900px;
	margin: 10px auto;
}
#areaTopicPath .topicPath,
#areaTopicPath .topicPath a {
	color: #999;
	font-size: 12px;
}

/** content
****************************************/
#content {
	width: 900px;
	margin: 20px auto 60px;
}
#content a { color: #51a2f4; }
#content h1 {
	margin: 25px auto;
	color: $c-main;
	font-size: 24px;
	font-weight: bold;
}
	
	/** App DL Button **/
	.appDL {
		overflow: hidden;
		padding: 40px 0;
		//background: $c-main;
		background-image: url(/common/pc/img/bg_2.jpg);
		background-repeat: repeat;
		text-align: center;
		color: #fff;
	}
	
	.appDL p { font-size: 17px; }
	.appDL em { color: #ff0; }
	.appDL li {
		display: inline-block;
		margin: 20px 12px 0;
	}
	.appDL a:hover img { opacity: 0.8; }
	.appDL .btnApple { opacity: 0.1; -ms-filter: "alpha(opacity=10)"; }
	
	/** [AD]Appliv **/
	.txtAd {
		line-height: 1.6;
		padding: 1.2em 0;
		background-color: #0057ad;
		color: #cbddee;
		font-size: 12px;
		text-align: center;
	}
		.txtAd a { color: #cbddee; }
	
/** form
****************************************/
.formDL dt {
	margin: 20px 0 0;
}
.formDL dt {
	font-size: 16px;
	font-weight: bold;
}
.formDL dd {
	margin-bottom: 1em;
}
.formDL input[type="text"],
.formDL input[type="email"] {
	width: 60%;
	height: 2em;
	line-height: 2em;
	border: 1px solid #ccc;
}
.formDL button#btnSubmit {
	display: block;
	width: 100px;
	height: 30px;
}
.formDL textarea {
	width: 60%;
	height: 6em;
	border: 1px solid #ccc;
	font-size: 100%;
}
.formDL select {
	height: 2em;
	line-height: 2em;
	border: 1px solid #ccc;
}
.formDL .formErr {
	border: 1px solid #f33 !important;
}
.formDL .err{
	margin-top: 3px;
	color: #f33;
}

/** footer
****************************************/
footer {
	overflow: hidden;
	padding: 20px 0;
	border-top: 1px solid #d7d7d7;
	background: #ececec;
	text-align: center;
}
footer ul { margin-bottom: 20px; }
footer li {
	display: inline-block;
	margin: 0 10px;
}
footer p { font-size: 12px; }

/** UP Button
****************************************/
#btnUP {
	position: fixed;
	right: 20px; bottom: 30px;
	display: none;
	width: 80px;
	height: 80px;
	line-height: 20px;
	border: 3px solid #ccc;
	border-radius: 50px;
	background: #fff;
	text-align: center;
	color: #444;
	cursor: pointer;
}
#btnUP:before {
	display: block;
	margin-bottom: 4px;
	content: "▲";
	color: #999;
	font-size: 16px;
	font-weight: bold;
}
#btnUP:hover {
	border: 3px solid $c-main;
	color: $c-main;
}
#btnUP:hover:before {
	color: $c-main;
}


/****************************************
Other Device(SP/Tablet)
****************************************/
@media screen and (max-width: 984px){
	
	/** topic path **/
	#areaTopicPath .topicPath {
		width: 90%;
	}
	
	/** content **/
	#content {
		width: 90%;
	}

	/** App DL Button **/
	.appDL {
		padding: 16px 0;
	}
	.appDL p { font-size: 16px; }
	.appDL li {
		width: 28%;
		margin: 8px 12px;
	}
	.appDL img { width: 100%; }

	/** footer
	****************************************/
	footer {
		overflow: hidden;
		padding: 20px 0;
		border-top: 1px solid #d7d7d7;
		background: #ececec;
		text-align: center;
	}
	footer ul { margin-bottom: 20px; }
	footer li {
		display: inline-block;
		margin: 0 10px;
	}
	footer p { font-size: 10px; }

	/** UP Button
	****************************************/
	#btnUP {
		position: fixed;
		right: 20px; bottom: 30px;
		display: none;
		width: 68px;
		height: 68px;
		line-height: 12px;
		border: 3px solid #ccc;
		border-radius: 50px;
		background: #fff;
		text-align: center;
		color: #444;
		cursor: pointer;
	}
	#btnUP:before {
		display: block;
		margin-bottom: 4px;
		content: "▲";
		color: #999;
		font-size: 14px;
		font-weight: bold;
	}
	#btnUP:hover {
		border: 3px solid $c-main;
		color: $c-main;
	}
	#btnUP:hover:before {
		color: $c-main;
	}

}
@media screen and (max-width: 700px){
	body { font-size: 12px; }
	
	dl.column { margin-top: 10px; }
	
	.spimg_full { width: 100%; }	
	
	/** topic path **/
	#areaTopicPath .topicPath {
		width: 90%;
		margin: 10px auto;
	}
	
	/** content **/
	#content {
		width: 90%;
		margin: 10px auto 30px;
	}
	#content h1 {
		margin: 10px auto;
		color: $c-main;
		font-size: 18px;
		font-weight: bold;
	}
	
	/** App DL Button **/
	.appDL {
		padding: 16px 0;
	}
	.appDL p { font-size: 12px; }
	.appDL li {
		width: 42%;
		margin: 8px 8px;
	}
	.appDL img { width: 100%; }
	.txtAd {
		padding: 1.2em 0.5em;
		font-size: 11px;
		text-align: left;
	}
	
	/** form **/
	.formDL dt { margin: 20px 0 0; }
	.formDL dt { font-size: 14px; }
	.formDL input[type="text"],
	.formDL input[type="email"] {
		width: 100%;
	}
	.formDL button#btnSubmit {
		display: block;
		width: 100px;
		height: 30px;
		margin: 0 auto;
		font-size: 16px;
	}
	.formDL textarea {
		width: 100%;
		height: 8em;
	}
	
}